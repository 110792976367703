<template>
  <div class="demo-page" ref="demoPage">
    <!-- Sticky Header -->
    <StickyHeaderComponent />

    <div v-if="submitted" class="thank-you-message">
      <i class="fas fa-check-circle thank-you-icon"></i> <!-- Checkmark Icon -->
      <h2>Thank you! We got your submission.</h2>
      <button @click="goHome" class="home-button">Take me to Home</button>
    </div>

    <div v-else>


    <!-- Campaign Questionnaire -->
    <div class="questionnaire-container">
      <h1 class="title">Campaign Questionnaire</h1>

        <!-- Section 0: Campaign Type -->
        <div class="question-section">
          <h2 :class="{ 'error': hasErrors.sellType }">
            1. What do you sell? <span v-if="hasErrors.sellType">*</span>
          </h2>
          <div class="other-input">
            <input
              type="text"
              v-model="sellType"
              maxlength="200"
              placeholder="Enter what you sell (200 characters max)"
            />
          </div>
        </div>

                <!-- Section 1: Campaign Type -->
          <div class="question-section">
          <h2 :class="{ 'error': hasErrors.campaignType }">
            2. What type of campaign is this? <span v-if="hasErrors.campaignType">*</span>
          </h2>
          <div class="question-buttons">
            <button v-for="type in campaignTypes" :key="type" @click="setCampaignType(type)"
                    :class="{'selected': selectedCampaignType === type}">
              {{ type }}
            </button>
          </div>
        </div>

              <!-- Section 2: Objectives -->
        <div class="question-section">
        <h2 :class="{ 'error': hasErrors.objectives }">
          3. What are the main objective(s) of your marketing campaign? <span v-if="hasErrors.objectives">*</span>
          </h2>
          <div class="question-buttons">
          <button v-for="objective in objectives" :key="objective" @click="toggleObjective(objective)" 
                  :class="{'selected': isObjectiveSelected(objective)}">
            {{ objective }}
          </button>
        </div>
      </div>


      <!-- Section 3: Target Audience Selection -->
      <div class="question-section">
        <h2 :class="{ error: hasErrors.targetAudience }">
          4. Who is your target audience for this campaign?
          <span v-if="hasErrors.targetAudience">*</span>
        </h2>
        <div class="instruction" style="font-size: 10px; font-style: italic;">
          <h3>Targeting Options</h3>
          <p><strong>"All"</strong> indicates that all items are included in the target group and will be analyzed with a normal distribution.</p>
          <p><strong>"N/A"</strong> signifies that the option is not relevant to the product and will be excluded entirely from the analysis when generating personas.</p>
          <p>These selections help ensure that targeting is both accurate and aligned with the intended audience.</p>
      </div>

        <div class="dropdown-group">
          <!-- Dropdown for Age -->
          <!-- Multi-select for Age -->
          <div>
            <label for="age">Age</label>
            <Multiselect
              v-model="targetAudience.age"
              mode="tags"
              :close-on-select="false"
              :searchable="false"
              :create-option="true"
              :options="ageOptions"
              :placeholder="'Please select an age(s)'"
            />
          </div>

          <!-- Dropdown for Gender -->
          <div>
            <label for="gender">Gender</label>
            <select v-model="targetAudience.gender" id="gender">
              <option disabled value="">Please select an gender group</option>
              <option>Male-leaning</option>
              <option>Female-leaning</option>
              <option>Both</option>
            </select>
          </div>

          <!-- Dropdown for Marital Status -->
          <div>
            <label for="maritalStatus">Marital Status</label>
            <Multiselect
              v-model="targetAudience.maritalStatus"
              mode="tags"
              :close-on-select="false"
              :searchable="false"
              :create-option="true"
              :options="maritalStatusOptions"
              :placeholder="'Please select a marital status(es)'"
            />
          </div>

          <!-- Dropdown for Family Lifecycle -->
          <div>
            <label for="familyLifecycle">Family Lifecycle</label>
            <Multiselect
              v-model="targetAudience.familyLifecycle"
              mode="tags"
              :close-on-select="false"
              :searchable="false"
              :create-option="true"
              :options="familyLifecycleOptions"
              :placeholder="'Please select a family lifecycle(s)'"
            />
          </div>


          <!-- Dropdown for Income -->
          <div>
            <label for="income">Income</label>
            <Multiselect
              v-model="targetAudience.income"
              mode="tags"
              :close-on-select="false"
              :searchable="false"
              :create-option="true"
              :options="incomeOptions"
              :placeholder="'Please select an income(s)'"
            />
          </div>

          <!-- Dropdown for Education -->
          <div>
            <label for="education">Education</label>
            <Multiselect
              v-model="targetAudience.education"
              mode="tags"
              :close-on-select="false"
              :searchable="false"
              :create-option="true"
              :options="educationOptions"
              :placeholder="'Please select an education(s)'"
            />
          </div>

          <!-- Dropdown for Region -->
          <div>
            <label for="region">Region</label>
            <select v-model="targetAudience.region" id="region">
              <option disabled value="">Please select region</option>
              <option>Northeast</option>
              <option>Midwest</option>
              <option>South</option>
              <option>West</option>
              <option>All</option>
              <option>N/A</option>
            </select>
          </div>

          <!-- Dropdown for City Size -->
          <div>
            <label for="citySize">City Size</label>
            <select v-model="targetAudience.citySize" id="citySize">
              <option disabled value="">Please select city size</option>
              <option>Under 20k</option>
              <option>20k-50k</option>
              <option>50k-100k</option>
              <option>100k-250k</option>
              <option>250k-500k</option>
              <option>500k-1m</option>
              <option>1m-4m</option>
              <option>Over 4m</option>
              <option>All</option>
              <option>N/A</option>
            </select>
          </div>

          <!-- Dropdown for Urbancity -->
          <div>
            <label for="urbancity">Urbanicity</label>
            <select v-model="targetAudience.urbancity" id="urbancity">
              <option disabled value="">Please select urbanicity</option>
              <option>Urban</option>
              <option>Suburban</option>
              <option>Rural</option>
              <option>Metro Mix</option>
              <option>Town</option>
            </select>
          </div>
        </div>
      </div>

            <!-- Section 3: What key message or value proposition do you plan to communicate? -->
            <div class="question-section">
              <h2 :class="{ 'error': hasErrors.keyValue }">
                      5. What key message or value proposition do you plan to communicate? <span v-if="hasErrors.keyValue">*</span>
                  </h2>
                  <div class="question-buttons">
                      <button v-for="keyvalue in keyValues" :key="keyvalue" @click="setKeyValue(keyvalue)"
                              :class="{'selected': selectedKeyValue === keyvalue}">
                          {{ keyvalue }}
                      </button>
                      <div v-if="selectedKeyValue === 'Other'" class="other-input">
                          <input type="text" v-model="customKeyValue" maxlength="200" placeholder="Enter your key value (200 characters max)" />
                      </div>
                  </div>
              </div>


      

            <!-- Section 4 -->
            <div class="question-section">
              <h2 :class="{ error: hasErrors.marketingChannels }">
                6. Which marketing channels do you intend to use and why?
                <span v-if="hasErrors.marketingChannels">*</span>
              </h2>
              <div class="question-buttons">
                <button
                  v-for="channel in marketingChannels"
                  :key="channel"
                  @click="setMarketingChannel(channel)"
                  :class="{ selected: selectedMarketingChannel === channel }"
                >
                  {{ channel }}
                </button>
                <!-- Display input for 'Other' if selected -->
                <div v-if="selectedMarketingChannel === 'Other'" class="other-input">
                  <input
                    type="text"
                    v-model="customMarketingChannel"
                    maxlength="200"
                    placeholder="Please specify other marketing channel"
                  />
                </div>
              </div>
            </div>

      <!-- Section 7: How does this campaign align with your overall brand strategy? -->
      <div class="question-section">
        <h2 :class="{ 'error': hasErrors.brandStrategy }">
            7. How does this campaign align with your overall brand strategy? <span v-if="hasErrors.brandStrategy">*</span>
          </h2>
          <div class="question-buttons">
          <button v-for="strategy in brandStrategies" :key="strategy" @click="setBrandStrategy(strategy)"
                  :class="{'selected': selectedBrandStrategy === strategy}">
            {{ strategy }}
          </button>
          <div v-if="selectedBrandStrategy === 'Other'" class="other-input">
            <input type="text" v-model="customBrandStrategy" maxlength="200" placeholder="Enter your brand strategy (200 characters max)" />
          </div>
        </div>
      </div>

      <!-- Section 8: What innovative or creative elements are you planning to incorporate? -->
      <div class="question-section">
        <h2 :class="{ 'error': hasErrors.creativeElements }">
          8. What innovative or creative elements are you planning to incorporate? <span v-if="hasErrors.creativeElements">*</span>
        </h2>
        <div class="question-buttons">
          <button v-for="element in creativeElements" :key="element" @click="toggleElement(element)"
                  :class="{'selected': isElementSelected(element)}">
            {{ element }}
          </button>
          <div v-if="isElementSelected('Other')" class="other-input">
            <input type="text" v-model="customElement" maxlength="200" placeholder="Enter custom element (200 characters max)" />
          </div>
        </div>
      </div>

      <!-- Section 12: How does this campaign differ from your previous marketing efforts? -->
      <div class="question-section">
        <h2 :class="{ 'error': hasErrors.previousCampaignDiff }">
          9. How does this campaign differ from your previous marketing efforts? <span v-if="hasErrors.previousCampaignDiff">*</span>
        </h2>
        <div class="question-buttons">
          <button v-for="difference in previousCampaignDiff" :key="difference" @click="setPreviousCampaignDiff(difference)"
                  :class="{'selected': selectedPreviousCampaignDiff === difference}">
            {{ difference }}
          </button>
          <div v-if="selectedPreviousCampaignDiff === 'Other'" class="other-input">
            <input type="text" v-model="customPreviousCampaignDiff" maxlength="200" placeholder="Enter your difference (200 characters max)" />
          </div>
        </div>
      </div>

      <!-- Section 13: Are there any market trends or consumer behaviors influencing your campaign strategy? -->
      <div class="question-section">
        <h2 :class="{ 'error': hasErrors.marketTrend }">
          10. Are there any market trends or consumer behaviors influencing your campaign strategy? <span v-if="hasErrors.marketTrend">*</span>
        </h2>
        <div class="question-buttons">
          <button v-for="trend in marketTrends" :key="trend" @click="setMarketTrend(trend)"
                  :class="{'selected': selectedMarketTrend === trend}">
            {{ trend }}
          </button>
          <div v-if="selectedMarketTrend === 'Other'" class="other-input">
            <input type="text" v-model="customMarketTrend" maxlength="200" placeholder="Enter your market trend (200 characters max)" />
          </div>
        </div>
      </div>

      <!-- Section 14: What primary tone or language style will your campaign use? -->
      <div class="question-section">
        <h2 :class="{ 'error': hasErrors.toneStyle }">
          11. What primary tone or language style will your campaign use? <span v-if="hasErrors.toneStyle">*</span>
        </h2>
          <div class="question-buttons">
          <button v-for="tone in toneStyles" :key="tone" @click="setToneStyle(tone)"
                  :class="{'selected': selectedToneStyle === tone}">
            {{ tone }}
          </button>
          <div v-if="selectedToneStyle === 'Other'" class="other-input">
            <input type="text" v-model="customToneStyle" maxlength="200" placeholder="Enter your tone style (200 characters max)" />
          </div>
        </div>
      </div>

      <!-- Section 15: Why did you choose this particular tone for your campaign? -->
      <div class="question-section">
        <h2 :class="{ 'error': hasErrors.toneReason }">
          12. Why did you choose this particular tone for your campaign? <span v-if="hasErrors.toneReason">*</span>
        </h2>
        <div class="question-buttons">
          <button v-for="reason in toneReasons" :key="reason" @click="setToneReason(reason)"
                  :class="{'selected': selectedToneReason === reason}">
            {{ reason }}
          </button>
          <div v-if="selectedToneReason === 'Other'" class="other-input">
            <input type="text" v-model="customToneReason" maxlength="200" placeholder="Enter your reason (200 characters max)" />
          </div>
        </div>
      </div>

      <!-- Section 16: Email Address -->
      <div class="question-section">
        <h2 :class="{ 'error': hasErrors.email }">
          13. Please enter your email address: <span v-if="hasErrors.email">*</span>
        </h2>
        <div class="other-input">
          <input type="email" v-model="email" maxlength="200" placeholder="Enter your email address" />
        </div>
      </div>
      
      <!-- Submit Button -->
      <div class="submit-container">
        <button class="submit-button" @click="submitQuestionnaire">Submit</button>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import StickyHeaderComponent from '../components/Header-Sticky.vue';
import Multiselect from '@vueform/multiselect';

export default {
  components: {
    StickyHeaderComponent,
    Multiselect
  },
  data() {
    return {
      submitted: false, 

      // Sell Types

      // Campaign Types
      campaignTypes: [
        'Brand awareness campaigns',
        'Product launch campaigns',
        'Lead generation campaigns',
        'Content marketing campaigns',
        'Social media campaigns', 
        'Retargeting campaigns',
        'Seasonal or holiday campaigns',
        'Referral campaigns',
        'User-generated content campaigns',
        'Guerrilla marketing campaigns',
        'Event marketing campaigns',
        'Email marketing campaigns',
        'Cause marketing campaigns',
        'Loyalty program campaigns',
        'Influencer marketing campaigns',
      ],
      selectedCampaignType: '',

      // Objectives
      objectives: [
        'Increase brand awareness',
        'Generate leads',
        'Drive sales',
        'Improve customer retention',
        'Launch a new product/service',
        'Enhance brand reputation',
        'Enter a new market',
        'Reposition the brand'
      ],
      selectedObjectives: [],

      // Target Audience Object
      targetAudience: {
        age: [],
        gender: '', // Gender remains a single selection
        maritalStatus: [],
        familyLifecycle: [],
        income: [],
        education: [],
        region: '', // Region remains a single selection
        citySize: '', // City Size remains a single selection
        urbancity: '', // Urbanicity remains a single selection
      },
      ageOptions: ["18-24", "25-35", "36-45", "46-55", "56-65", "65+"],
      maritalStatusOptions: ["Single", "Married", "Divorced", "Widowed", "All", "N/A"],
      familyLifecycleOptions: ["No children", "Youngest child under 6", "Youngest child 6 or over", "Youngest child over 12", "No children under 18", "All", "N/A"],
      incomeOptions: [
        "Below $10,000",
        "$10,000-$29,999",
        "$30,000-$59,999",
        "$60,000-$99,999",
        "$100,000-$149,999",
        "$150,000-$299,999",
        "$300,000+",
        "All"
      ],
      educationOptions: [
        "Grade school or less",
        "Some high school",
        "High School Graduate",
        "Some college",
        "Bachelor's Degree",
        "Advanced Degrees",
        "All",
        "N/A"
      ],
        // Key Value
        keyValues: [
        'Product Quality',
        'Cost savings',
        'Convenience',
        'Innovation',
        'Exclusivity',
        'Sustainability',
        'Customer service',
        'Lifestyle enhancement',
        'Problem-solving',
        'Other'
      ],
      selectedKeyValue: '',
      customKeyValue: '',

      // Marketing Channels
      marketingChannels: [
        'Social media',
        'Email marketing',
        'Content marketing',
        'Paid advertising',
        'Influencer marketing',
        'Traditional media (TV, radio, print)',
        'Event marketing',
        'Search engine optimization (SEO)',
        'Direct mail',
        'Other',
      ],
      selectedMarketingChannel: '',
      customMarketingChannel: '',



      // Brand Strategies
      brandStrategies: [
        'Reinforces existing brand image',
        'Introduces a new brand direction',
        'Expands to new market segments',
        'Strengthens customer relationships',
        'Differentiates from competitors',
        'Highlights corporate social responsibility',
        'Showcases innovation',
        'Other',
      ],
      selectedBrandStrategy: '',
      customBrandStrategy: '',

      // Creative Elements
      creativeElements: [
        'Interactive experiences',
        'User-generated content',
        'Augmented or Virtual Reality',
        'Artificial Intelligence',
        'Personalization at scale',
        'Storytelling',
        'Gamification',
        'Live streaming',
        'Other',
      ],
      selectedElements: [],
      customElement: '',


      // Previous Campaign Difference
      previousCampaignDiff: [
        'Targets a new audience',
        'Uses new channels',
        'Incorporates new technologies',
        'Has a larger budget',
        'Focuses on different messaging',
        'Involves more cross-team collaboration',
        'Emphasizes different metrics',
        'Other',
      ],
      selectedPreviousCampaignDiff: '',
      customPreviousCampaignDiff: '',

      // Market Trends
      marketTrends: [
        'Increased focus on sustainability',
        'Growing importance of social responsibility',
        'Shift towards mobile-first experiences',
        'Rise of voice search and smart speakers',
        'Demand for more personalized experiences',
        'Preference for authentic user-generated content',
        'Increased privacy concerns',
        'Other',
      ],
      selectedMarketTrend: '',
      customMarketTrend: '',

      // Tone Styles
      toneStyles: [
        'Humorous/Playful',
        'Emotional/Sentimental',
        'Formal/Professional',
        'Casual/Conversational',
        'Inspirational/Motivational',
        'Educational/Informative',
        'Provocative/Controversial',
        'Urgent/Time-sensitive',
        'Luxurious/Sophisticated',
        'Minimalist/Straightforward',
        'Empathetic/Understanding',
        'Authoritative/Expert',
        'Nostalgic/Retro',
        'Futuristic/Innovative',
        'Other',
      ],
      selectedToneStyle: '',
      customToneStyle: '',

      // Tone Reasons
      toneReasons: [
        'Aligns with brand personality',
        'Resonates with target audience',
        'Differentiates from competitors',
        'Suits the product/service being marketed',
        'Fits the chosen marketing channels',
        'Addresses current market trends',
        'Reflects cultural context',
        'Supports campaign objectives',
        'Based on successful past campaigns',
        'Other',
      ],
      selectedToneReason: '',
      customToneReason: '',

      hasErrors: {
        sellType: false,
        campaignType: false,
        objectives: false,
        targetAudience: false,
        marketingChannels: false,
        brandStrategy: false,
        creativeElements: false,
        previousCampaignDiff: false,
        marketTrend: false,
        toneStyle: false,
        toneReason: false,
        email: false,
      },
    };
  },
  methods: {
    goHome() {
      this.$router.push('/'); // Redirect to home page
    },
    // Section 1: Campaign Type
    setCampaignType(type) {
      this.selectedCampaignType = type;
    },

    // Section 2: Objectives
    toggleObjective(objective) {
      const index = this.selectedObjectives.indexOf(objective);
      if (index > -1) {
        this.selectedObjectives.splice(index, 1);
      } else {
        this.selectedObjectives.push(objective);
      }
    },
    isObjectiveSelected(objective) {
      return this.selectedObjectives.includes(objective);
    },
    setKeyValue(keyvalue) {
      this.selectedKeyValue = keyvalue;
      if (keyvalue !== 'Other') {
        this.customKeyValue = '';
      }
    },

    // Section 4: Marketing Channels
    setMarketingChannel(channel) {
      this.selectedMarketingChannel = channel;
      if (channel !== 'Other') {
        this.customMarketingChannel = '';
      }
    },


    // Section 7: Brand Strategy
    setBrandStrategy(strategy) {
      this.selectedBrandStrategy = strategy;
      if (strategy !== 'Other') {
        this.customBrandStrategy = '';
      }
    },

    // Section 8: Creative Elements
    toggleElement(element) {
      const index = this.selectedElements.indexOf(element);
      if (index > -1) {
        this.selectedElements.splice(index, 1);
      } else {
        this.selectedElements.push(element);
      }
    },
    isElementSelected(element) {
      return this.selectedElements.includes(element);
    },

    // Section 12: Previous Campaign Difference
    setPreviousCampaignDiff(difference) {
      this.selectedPreviousCampaignDiff = difference;
      if (difference !== 'Other') {
        this.customPreviousCampaignDiff = '';
      }
    },

    // Section 13: Market Trends
    setMarketTrend(trend) {
      this.selectedMarketTrend = trend;
      if (trend !== 'Other') {
        this.customMarketTrend = '';
      }
    },

    // Section 14: Tone Style
    setToneStyle(tone) {
      this.selectedToneStyle = tone;
      if (tone !== 'Other') {
        this.customToneStyle = '';
      }
    },

    // Section 15: Tone Reason
    setToneReason(reason) {
      this.selectedToneReason = reason;
      if (reason !== 'Other') {
        this.customToneReason = '';
      }
    },

    // Submit the Questionnaire
    async submitQuestionnaire() {
      let hasError = false;

      // Validate Section 0: Sell Type
      if (!this.sellType) {
        this.hasErrors.sellType = true;
        hasError = true;
        console.log('Error in Sell Type: No sell type provided');
      } else {
        this.hasErrors.sellType = false;
      }
      // Validate Section 1: Campaign Type
      if (!this.selectedCampaignType) {
        this.hasErrors.campaignType = true;
        hasError = true;
        console.log('Error in Campaign Type: No campaign type selected');
      } else {
        this.hasErrors.campaignType = false;
      }

      // Validate Section 2: Objectives
      if (this.selectedObjectives.length === 0) {
        this.hasErrors.objectives = true;
        hasError = true;
        console.log('Error in Objectives: No objectives selected');
      } else {
        this.hasErrors.objectives = false;
      }

      // Validate Section 3: Target Audience
      if (this.targetAudience.age.length === 0) {
          this.hasErrors.targetAudience = true;
          hasError = true;
          console.log('Error in Target Audience: At least one age group must be selected');
        } else {
          this.hasErrors.targetAudience = false;
        }

      // Validate Section 4: Marketing Channels
      if (
        !this.selectedMarketingChannel &&
        !this.customMarketingChannel
      ) {
        this.hasErrors.marketingChannels = true;
        hasError = true;
        console.log('Error in Marketing Channels: No channel selected');
      } else {
        this.hasErrors.marketingChannels = false;
      }
      // Validate Section 3: KEY VALUE
      if (!this.selectedKeyValue && !this.customKeyValue) {
        this.hasErrors.keyValue = true;
        hasError = true;
        console.log('Error in Key Value: No key value selected');
        } else {
            this.hasErrors.keyValue = false;
        }

      // Validate Section 7: Brand Strategy
      if (
        !this.selectedBrandStrategy &&
        !this.customBrandStrategy
      ) {
        this.hasErrors.brandStrategy = true;
        hasError = true;
        console.log('Error in Brand Strategy: No strategy selected');
      } else {
        this.hasErrors.brandStrategy = false;
      }

      // Validate Section 8: Creative Elements
      if (
        this.selectedElements.length === 0 &&
        !this.customElement
      ) {
        this.hasErrors.creativeElements = true;
        hasError = true;
        console.log('Error in Creative Elements: No elements selected');
      } else {
        this.hasErrors.creativeElements = false;
      }

      // Validate Section 12: Previous Campaign Difference
      if (
        !this.selectedPreviousCampaignDiff &&
        !this.customPreviousCampaignDiff
      ) {
        this.hasErrors.previousCampaignDiff = true;
        hasError = true;
        console.log(
          'Error in Previous Campaign Difference: No difference selected'
        );
      } else {
        this.hasErrors.previousCampaignDiff = false;
      }

      // Validate Section 13: Market Trend
      if (
        !this.selectedMarketTrend &&
        !this.customMarketTrend
      ) {
        this.hasErrors.marketTrend = true;
        hasError = true;
        console.log('Error in Market Trend: No trend selected');
      } else {
        this.hasErrors.marketTrend = false;
      }

      // Validate Section 14: Tone Style
      if (!this.selectedToneStyle && !this.customToneStyle) {
        this.hasErrors.toneStyle = true;
        hasError = true;
        console.log('Error in Tone Style: No tone style selected');
      } else {
        this.hasErrors.toneStyle = false;
      }

      // Validate Section 15: Tone Reason
      if (!this.selectedToneReason && !this.customToneReason) {
        this.hasErrors.toneReason = true;
        hasError = true;
        console.log('Error in Tone Reason: No reason selected');
      } else {
        this.hasErrors.toneReason = false;
      }
      if (!this.email) {
        this.hasErrors.email = true;
        hasError = true;
        console.log('Error in Email Address: No email address provided');
      } else {
        this.hasErrors.email = false;
      }

      // Check if there are any errors
      if (hasError) {
        alert('Please complete all required fields.');
        return;
      }

      // Prepare campaign details
      const campaignDetails = {
        email: this.email,
        campaignType: this.selectedCampaignType,
        objectives: this.selectedObjectives,
        targetAudience: this.targetAudience,
        marketingChannel:
          this.selectedMarketingChannel === 'Other'
            ? this.customMarketingChannel
            : this.selectedMarketingChannel,
        brandStrategy:
          this.selectedBrandStrategy === 'Other'
            ? this.customBrandStrategy
            : this.selectedBrandStrategy,
        creativeElements: this.selectedElements.includes('Other')
          ? [
              ...this.selectedElements.filter(
                (el) => el !== 'Other'
              ),
              this.customElement,
            ]
          : this.selectedElements,
          keyValue:
            this.selectedKeyValue === 'Other'
                ? this.customKeyValue
                : this.selectedKeyValue, 
        previousCampaignDifference:
          this.selectedPreviousCampaignDiff === 'Other'
            ? this.customPreviousCampaignDiff
            : this.selectedPreviousCampaignDiff,
        marketTrend:
          this.selectedMarketTrend === 'Other'
            ? this.customMarketTrend
            : this.selectedMarketTrend,
        toneStyle:
          this.selectedToneStyle === 'Other'
            ? this.customToneStyle
            : this.selectedToneStyle,
        toneReason:
          this.selectedToneReason === 'Other'
            ? this.customToneReason
            : this.selectedToneReason,
      };

      // Prepare the data to send to Google Forms
      const formData = new URLSearchParams();
      formData.append('entry.617589951', this.sellType);
      // Map Vue.js data to Google Form entry IDs
      formData.append('entry.934550263', this.selectedCampaignType); // Campaign Type

      this.selectedObjectives.forEach(objective => {
          formData.append('entry.599150444', objective);
        });// Objectives
        // For Age
      this.targetAudience.age.forEach(age => {
        formData.append('entry.376877753', age); // Replace with correct Google Form entry ID
      });

      // For Marital Status
      this.targetAudience.maritalStatus.forEach(status => {
        formData.append('entry.22969741', status); // Replace with correct Google Form entry ID
      });

      // For Family Lifecycle
      this.targetAudience.familyLifecycle.forEach(lifecycle => {
        formData.append('entry.1001452286', lifecycle); // Replace with correct Google Form entry ID
      });

      // For Income
      this.targetAudience.income.forEach(income => {
        formData.append('entry.1669175268', income); // Replace with correct Google Form entry ID
      });

      // For Education
      this.targetAudience.education.forEach(education => {
        formData.append('entry.787220392', education); // Replace with correct Google Form entry ID
      });
      formData.append('entry.103915780', this.targetAudience.gender); // Gender
      formData.append('entry.195503636', this.targetAudience.region); // Region
      formData.append('entry.586542321', this.targetAudience.citySize); // City Size
      formData.append('entry.441556711', this.targetAudience.urbancity); // Urbanicity

      // Map other fields similarly

      if (this.selectedKeyValue === 'Other') {
        formData.append('entry.1662687644.other_option_response', this.customKeyValue);
    } else {
        formData.append('entry.1662687644', this.selectedKeyValue);
    }

    if (this.selectedPreviousCampaignDiff === 'Other') {
        formData.append('entry.377959753.other_option_response', this.customPreviousCampaignDiff);
    } else {
        formData.append('entry.377959753', this.selectedPreviousCampaignDiff);
    }


    
      if (this.selectedMarketingChannel === 'Other') {
        formData.append('entry.113966502.other_option_response', this.customMarketingChannel);
      } else {
        formData.append('entry.113966502', this.selectedMarketingChannel);
      }

      // For Marketing Trends
      if (this.selectedMarketTrend.includes('Other')) {
        formData.append('entry.871770820.other_option_response', this.customMarketTrend);
      } else {
        formData.append('entry.871770820', this.selectedMarketTrend);
      }

      // For Brand Strategy
      if (this.selectedBrandStrategy === 'Other') {
        formData.append('entry.557701969.other_option_response', this.customBrandStrategy);
      } else {
        formData.append('entry.557701969', this.selectedBrandStrategy);
      }

      // For Creative Elements
      if (this.selectedElements.includes('Other')) {
        formData.append('entry.985729999.other_option_response', this.customElement);
      } else {
        formData.append('entry.985729999', this.selectedElements.join(', '));
      }

      // For Tone Style
      if (this.selectedToneStyle === 'Other') {
        formData.append('entry.504881477.other_option_response', this.customToneStyle);
      } else {
        formData.append('entry.504881477', this.selectedToneStyle);
      }

      // For Tone Reason
      if (this.selectedToneReason === 'Other') {
        formData.append('entry.42474847.other_option_response', this.customToneReason);
      } else {
        formData.append('entry.42474847', this.selectedToneReason);
      }
      formData.append('entry.543760413', this.email); // Email from user
      // Send the data to the Google Form
      try {
            // Create the full URL with all the form data as query parameters
        const url = `https://docs.google.com/forms/d/e/1FAIpQLSefDKVvigAsFcW1inqj8y_qUwgA5jdzjeXBWmSRhWu2erhMOw/formResponse?${formData.toString()}`;
        console.log(url);
        // Open the URL in a new tab to submit the form
        window.open(url, '_blank');

        this.submitted = true;
      } catch (error) {
        console.error('Error submitting the form:', error);
        alert('There was an issue submitting the form.');
      }
    }
  }
};
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style>
.multiselect-tag {
  background: #f4633a !important;
  font-size: 10px !important;
  max-width: 100px !important;
  min-width: 55px !important;
}
.multiselect.is-active {
  box-shadow: none !important;
}
.multiselect-option {
  font-size: 11px !important;
}
.multiselect-wrapper {
  font-size: 12px !important;
  background: #381e72 !important;
  color: white !important;
}
.multiselect-wrapper::placeholder {
  color: white !important;
}
</style>
<style scoped>
/* General Page Layout */
.demo-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Montserrat', sans-serif;
}
.sticky-header {
  max-width: 1200px;
}

/* Title Styling */
.title {
  font-size: 2em;
  margin-bottom: 40px;
  color: #381e72;
  text-align: center;
  margin-top: 15%;
}
/* Thank You Message Styling */
.thank-you-message {
  text-align: center;
  margin-top: 100px;
}

.thank-you-icon {
  font-size: 3em;
  color: #28a745;
  margin-bottom: 20px;
}

.thank-you-message h2 {
  font-size: 1.8em;
  color: #381e72;
  margin-bottom: 20px;
}

/* Home Button Styling */
.home-button {
  background-color: #381e72;
  color: white;
  padding: 15px 30px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.2em;
  transition: background-color 0.3s ease;
}

.home-button:hover {
  background-color: #f4633a;
}
/* Question Section Styling */
.question-section {
  margin-bottom: 50px;
  margin-top: 5%;
}

.question-section h2 {
  font-size: 1.5em;
  margin-bottom: 20px;
  color: #381e72;
}

.question-section h2.error {
  color: red;
}

/* Dropdown Group Styling */
.dropdown-group {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
}

.dropdown-group label {
  font-weight: bold;
  margin-bottom: 5px;
  display: block;
}

.dropdown-group select {
  width: 100%;
  height: 42px;
  padding: 8px;
  border: 1px solid #381e72;
  background-color: #381e72;
  color: white;
  border-radius: 5px;
}
.dropdown-group select.placeholder {
  color: white;
}

/* Button Container */
.question-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.question-buttons button {
  padding: 10px 20px;
  background-color: #381e72;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  flex: 1 1 calc(33% - 30px);
  max-width: calc(33% - 30px);
  margin-bottom: 10px;
  text-align: center;
}

.question-buttons button.selected {
  background-color: #f4633a;
}

.question-buttons button:hover:not(.selected) {
  background-color: #573b89;
}

/* Other Input Styling */
.other-input {
  max-width: 92%;
  width: 100%;
  margin-top: 15px;
}

.other-input input {
  width: 100%;
  padding: 10px;
  border: 1px solid #381e72;
  border-radius: 5px;
}

/* Submit Button */
.submit-container {
  text-align: center;
  margin-top: 50px;
}

.submit-button {
  padding: 15px 30px;
  background-color: #381e72;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.2em;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: #f4633a;
}

/* Error State Styling */
.error {
  color: red;
}

.error span {
  color: red;
  margin-left: 5px;
}

/* Optional custom styles */
.multiselect {
  width: 100%;
  border-radius: 5px;
  border: 1px solid #381e72;
  background-color: #f8f8f8;
}

.multiselect__option--selected {
  background-color: #381e72;
  color: white;
}
</style>