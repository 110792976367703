<template>
    <section class="hero-section">
      <div class="hero-text">
        <h1>Transform Your Marketing Strategy with AI-Powered Testing</h1>
          <p>
            Mosaique helps business owners develop and test marketing campaigns through
            AI-simulated audience reactions, whether you're starting from scratch or optimizing
            existing strategies.
          </p>
          <button @click="joinWaitlist2">Join the Waitlist <i class="fas fa-arrow-right"></i></button>
      </div>
    </section>
  </template>
  
  <script>
  
  export default {
    name: 'HeroSectionComponent',
    methods: {
        joinWaitlist2() {
          document.getElementById('signup-section').scrollIntoView({ behavior: 'smooth' });
        },
      },
  };
  
  </script>
  
  <style scoped>
  .hero-section {
    background: linear-gradient(to right, #381e72, #f4633a);
    height: 100vh;
    /* width: 100vw; */
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .hero-text {
    position: absolute;
    text-align: center;
    color: white;
    z-index: 2;
  }
  
  .hero-text h1 {
    font-size: 3em;
    margin-bottom: 10px;
  }
  
  .hero-text p {
    font-size: 1.5em;
    max-width: 700px;
    margin: 0 auto;
  }
  .hero-section {
  background-color: #e8f0fe;
  text-align: center;
}

.hero-section h1 {
  font-size: 2.5em;
  margin-bottom: 20px;
}

.hero-section p {
  font-size: 1.2em;
  margin-bottom: 30px;
}

.hero-section button {
  background-color: #1a1a1a;
  color: white;
  border: none;
  padding: 15px 30px;
  font-size: 1.2em;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.hero-section button:hover {
  background-color: #333;
}
  </style>