<template>
    <footer class="footer">
      <p>© 2024 Mosaique | All Rights Reserved</p>
      <div class="social-links">
        <a href="#">Privacy Policy</a> | <a href="#">Contact Us</a>
      </div>
    </footer>
  </template>
    <script>
    export default {
      name: 'FooterComponent',
    }
    </script>
  <style scoped>
  .footer {
    padding: 20px;
    background-color: #381e72;
    color: white;
    text-align: center;
  }
  
  .footer .social-links a {
    color: white;
    text-decoration: none;
    margin: 0 10px;
  }
  </style>