<template>
    <section class="why-mosaique-section">
      <h2>Why Mosaique?</h2>
      <div class="steps-container">
        <!-- Step cards with icons, titles, and descriptions -->
        <div class="step" v-for="(step, index) in steps" :key="index">
          <div class="step-header">
            <div class="icon-container">
              <i :class="step.icon"></i>
            </div>
            <h3>{{ step.title }}</h3>
          </div>
          <p class="step-description">{{ step.description }}</p>
        </div>
      </div>
    </section>
    
    <section class="signup-section" id="signup-section">
      <h2>Get Started with Smarter Marketing</h2>
      <h3>Early Access Benefits:</h3>
      <ul class="benefits-list">
        <li v-for="(benefit, index) in benefits" :key="index">
          <i class="fas fa-check-circle"></i> {{ benefit }}
        </li>
      </ul>
  
      <!-- Conditional rendering for email input or thank you message -->
      <div v-if="waitlistSubmitted" class="thank-you-message">
        <i class="fas fa-check-circle thank-you-icon"></i> <!-- Checkmark icon -->
        <p>Thank you for joining the waitlist!</p>
      </div>
      <div v-else class="email-input-container">
        <input
          type="email"
          v-model="email"
          placeholder="Enter your email"
          @keyup.enter="joinWaitlist"
        />
        <button @click="joinWaitlist">Join Waitlist <i class="fas fa-arrow-right"></i></button>
      </div>
    </section>
  </template>
  
  <script>
  export default {
    data() {
      return {
        email: '',
        waitlistSubmitted: false, // New data property to control thank you message
        benefits: [
          'Priority access to our strategy creation tools',
          'Free strategy consultation session',
          'Early adopter benefits and pricing',
          'Direct input into feature development',
        ],
        steps: [
          { title: 'Strategy Uncertainty', description: '70% of businesses struggle to validate their marketing strategies before investing', icon: 'fas fa-chart-line' },
          { title: 'High Testing Costs', description: 'Traditional campaign testing consumes up to 10% of marketing budgets', icon: 'fas fa-dollar-sign' },
          { title: 'Persona-Based', description: 'Receive detailed feedback tailored to your target audience', icon: 'fas fa-users' },
        ],
      };
    },
    methods: {
      async joinWaitlist() {
        if (!this.isValidEmail(this.email)) {
          alert("Please enter a valid email address.");
          return;
        }
  
        try {
          const response = await fetch("https://script.google.com/macros/s/AKfycbxD_GVDrkqwHiN4IpGgi7eQIwFN0oFTVinPfNv2GgFGvCpvtRgNTjAk7SEmCyS5X-JXtg/exec", {
            method: "POST",
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
            body: new URLSearchParams({ email: this.email }),
          });
  
          let result;
          try {
            result = await response.json();
          } catch (parseError) {
            console.error("Failed to parse response as JSON:", parseError);
            console.error("Raw response:", await response.text()); // Log raw text for further debugging
            throw new Error("Unexpected server response format.");
          }
  
          if (result.status === 'success') {
            this.waitlistSubmitted = true; // Show thank you message
            this.email = ""; // Reset email input
          } else {
            console.log("Something went wrong. Please try again later.");
          }
        } catch (error) {
          console.error("Error joining waitlist:", error);
          this.waitlistSubmitted = true;
        }
      },
      isValidEmail(email) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
      },
    },
  };
  </script>
  
  <style scoped>
  .signup-section {
    max-width: 600px;
    margin: 40px auto;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .signup-section h2 {
    font-size: 1.8em;
    color: #1a1a1a;
    margin-bottom: 10px;
  }
  
  .signup-section h3 {
    font-size: 1.2em;
    color: #666;
    margin-bottom: 20px;
  }
  
  .benefits-list {
    list-style-type: none;
    padding: 0;
    margin-bottom: 20px;
    text-align: left;
  }
  
  .benefits-list li {
    font-size: 1.1em;
    color: #333;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .benefits-list i {
    color: #28a745; /* Green check icon color */
    margin-right: 8px;
  }
  
  .email-input-container {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .email-input-container input {
    flex: 1;
    padding: 10px;
    font-size: 1em;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin-right: 10px;
  }
  
  .email-input-container button {
    background-color: #381e72;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 1em;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .email-input-container button:hover {
    background-color: #2c165b;
  }
  
  /* Thank you message styling */
  .thank-you-message {
    text-align: center;
    color: #28a745;
    font-size: 1.2em;
    margin-top: 20px;
  }
  
  .thank-you-icon {
    font-size: 2em;
    margin-bottom: 10px;
  }
  
  /* Steps container styled as rows */
  .steps-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
  }
  
  /* Step cards with icon, title, and description */
  .step {
    display: flex;
    width: 75%;
    flex-direction: column;
    align-items: start;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    transition: transform 0.3s ease;
    text-align: start;
  }
  
  .step:hover {
    transform: translateY(-10px);
  }
  
  /* Header containing icon and title */
  .step-header {
    display: flex;
    align-items: center;
    gap: 10px; /* Space between icon and title */
    margin-bottom: 10px; /* Space between header and description */
  }
  
  /* Icon styling */
  .icon-container {
    font-size: 1.5em;
    color: #381e72;
  }
  
  /* Title styling */
  .step-header h3 {
    font-size: 1.5em;
    color: #381e72;
    margin: 0; /* Remove default margin */
  }
  .why-mosaique-section {
    padding: 50px;
    background-color: #ffffff;
    text-align: center;
  }
  
  .why-mosaique-section h2 {
    font-size: 2em;
    color: #1a1a1a;
    margin-bottom: 30px;
  }
  </style>