<template>
  <header :class="[headerClass, demoHeaderClass]" class="sticky-header">
    <nav class="sticky-header-nav">
      <img :src="logo" alt="Mosaique Logo" class="logo" :style="{ width: logoWidth }" />
      <ul>
        <li v-for="link in filteredLinks" :key="link.text" :class="{ active: link.active }">
          <a @click="navigate(link.url)" href="javascript:void(0)">
            {{ link.text }}
          </a>
          <span class="underline"></span>
        </li>
      </ul>
    </nav>
  </header>
</template>

<script>
export default {
  name: 'StickyHeaderComponent',
  data() {
    return {
      isScrolled: false,
      isDesktop: window.innerWidth > 769,
      links: [
        { text: 'Home', url: '/', active: false },
        { text: 'How it Works', url: '#how-it-works', active: false },
        { text: 'About', url: '#about', active: false },
        { text: 'Contact', url: '#contact', active: false },
      ],
      demoPageLinks: [
        { text: 'Home', url: '/', active: false },
        { text: 'Contact', url: '#contact', active: false },
      ],
      logoWhite: require('@/assets/logo_white.png'),
      logoBlack: require('@/assets/logo_black.png'),
    };
  },
  computed: {
    headerClass() {
      return this.isScrolled && (this.$route.name !== 'Demo' && this.$route.name !== 'Company-Demo') ? 'scrolled' : '';
    },
    demoHeaderClass() {
      return (this.$route.name === 'Demo' || this.$route.name === 'Company-Demo') ? 'demo-header' : '';
    },
    logo() {
      return (this.$route.name === 'Demo' || this.$route.name === 'Company-Demo') ? this.logoBlack : this.isScrolled ? this.logoBlack : this.logoWhite;
    },
    filteredLinks() {
      return (this.$route.name === 'Demo' || this.$route.name === 'Company-Demo') ? this.demoPageLinks : this.links;
    },
    logoWidth() {
      if (this.isDesktop) {
        if (this.$route.name === 'Demo' || this.$route.name === 'Company-Demo') {
          return '20%';
        } else if (this.$route.name === 'Home') {
          return '16%';
        }
      }
      return '40%';
    },
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
  handleScroll() {
    this.isScrolled = window.scrollY > 50;
  },
  navigate(url) {
    if (this.$route.name === 'Home' && url === '/') {
      // If already on the Home page, scroll to the hero section
      const element = document.querySelector('#hero');
      if (element) {
        window.scrollTo({
          top: element.offsetTop - 70, // Adjust for header height
          behavior: 'smooth',
        });
      }
    } else if (url === '/') {
      // Navigate to the home page
      this.$router.push('/');
    } else if (url.startsWith('#')) {
      const element = document.querySelector(url);
      if (element) {
        window.scrollTo({
          top: element.offsetTop - 70, // Adjust for header height
          behavior: 'smooth',
        });
      }
    }
  },
},
};
</script>

<style scoped>
html {
  scroll-behavior: smooth;
}
.sticky-header-nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 90%;
}

.sticky-header {
  position: fixed;
  top: 0;
  width: 100%;
  padding: 10px 20px;
  background-color: transparent;
  transition: background-color 0.3s ease, color 0.3s ease;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo {
  height: 100%;
  transition: all 0.3s ease;
}

.sticky-header ul {
  list-style: none;
  display: flex;
}

.sticky-header li {
  margin: 0 15px;
  position: relative;
}

.sticky-header a {
  color: white;
  text-decoration: none;
  transition: color 0.3s ease;
}

.sticky-header .underline {
  position: absolute;
  bottom: -2px;
  left: 0;
  height: 2px;
  width: 0;
  background-color: white;
  transition: width 0.3s ease;
}

.sticky-header li:hover .underline {
  width: 100%;
}

/* When scrolled, apply white background and black text */
.scrolled {
  background-color: white;
}

.scrolled a {
  color: black;
}

.scrolled .underline {
  background-color: black;
}

/* Special header styling for Demo.vue */
.demo-header {
  background-color: white;
}

.demo-header a {
  color: black !important;
}

.demo-header .underline {
  background-color: black;
}
@media screen and (max-width: 768px) {
  .logo {
    width: 40%;
    margin-top: 2.5%;
  }
}
</style>