  <template>
  <div class="demo-page">
    <!-- Sticky Header -->
    <StickyHeaderComponent />
    <div v-if="submitted" class="thank-you-message">
      <i class="fas fa-check-circle thank-you-icon"></i> <!-- Checkmark Icon -->
      <h2>Thank you! We got your submission.</h2>
      <button @click="goHome" class="home-button">Take me to Home</button>
    </div>

    <div v-else>
      <!-- Company Information Questionnaire -->
      <div class="questionnaire-container">
        <h1 class="title">Company Information Questionnaire</h1>

        <!-- Company Information Section -->
        <div class="question-section">
          <h2>Company Information</h2>
          <div class="question-buttons">
            <label :class="{ error: hasErrors.whatYouSell }">What do you sell?<span style="color:red;" v-if="hasErrors.whatYouSell">*</span></label>
            <input type="text" v-model="companyInfo.whatYouSell" :class="{ 'input-error': hasErrors.whatYouSell }" />

            <label :class="{ error: hasErrors.typicalBuyer }">Who typically buys from you?<span style="color:red;" v-if="hasErrors.typicalBuyer">*</span></label>
            <input type="text" v-model="companyInfo.typicalBuyer" :class="{ 'input-error': hasErrors.typicalBuyer }" />

            <label :class="{ error: hasErrors.reasonForStarting }">What made you start this business?<span style="color:red;" v-if="hasErrors.reasonForStarting">*</span></label>
            <input type="text" v-model="companyInfo.reasonForStarting" :class="{ 'input-error': hasErrors.reasonForStarting }" />

            <label :class="{ error: hasErrors.salesMethod }">How do you sell your products? (online/store/markets)<span style="color:red;" v-if="hasErrors.salesMethod">*</span> </label>
            <input type="text" v-model="companyInfo.salesMethod" :class="{ 'input-error': hasErrors.salesMethod }" />
          </div>
        </div>

        <!-- Customer Information Section -->
        <div class="question-section">
          <h2>Customer Information</h2>
          <div class="question-buttons">
            <label :class="{ error: hasErrors.likes }">What do customers usually say they like about your products?<span style="color:red;" v-if="hasErrors.likes">*</span></label>
            <input type="text" v-model="customerInfo.likes" :class="{ 'input-error': hasErrors.likes }" />

            <label :class="{ error: hasErrors.commonQuestions }">What questions do customers ask most often?<span style="color:red;" v-if="hasErrors.commonQuestions">*</span></label>
            <input type="text" v-model="customerInfo.commonQuestions" :class="{ 'input-error': hasErrors.commonQuestions }" />

            <label :class="{ error: hasErrors.priceRange }">How much are customers usually willing to pay?<span style="color:red;" v-if="hasErrors.priceRange">*</span></label>
            <input type="text" v-model="customerInfo.priceRange" :class="{ 'input-error': hasErrors.priceRange }" />
          </div>
        </div>

        <!-- Current Marketing Information Section -->
        <div class="question-section">
          <h2>Current Marketing Information</h2>
          <div class="question-buttons">
            <label :class="{ error: hasErrors.communicationMethods }">How do you currently tell people about your business? <span style="color:red;" v-if="hasErrors.communicationMethods">*</span></label>
            <input type="text" v-model="marketingInfo.communicationMethods" :class="{ 'input-error': hasErrors.communicationMethods }" />

            <label :class="{ error: hasErrors.socialMediaPlatforms }">Are you using social media? Which platforms?<span style="color:red;" v-if="hasErrors.socialMediaPlatforms">*</span></label>
            <input type="text" v-model="marketingInfo.socialMediaPlatforms" :class="{ 'input-error': hasErrors.socialMediaPlatforms }" />

            <label :class="{ error: hasErrors.effectiveStrategies }">What's working best to get sales right now?<span style="color:red;" v-if="hasErrors.effectiveStrategies">*</span></label>
            <input type="text" v-model="marketingInfo.effectiveStrategies" :class="{ 'input-error': hasErrors.effectiveStrategies }" />

            <label :class="{ error: hasErrors.challenges }">What's your biggest challenge in getting new customers?<span style="color:red;" v-if="hasErrors.challenges">*</span></label>
            <input type="text" v-model="marketingInfo.challenges" :class="{ 'input-error': hasErrors.challenges }" />
          </div>
        </div>

        <!-- Goals and Budget Section -->
        <div class="question-section">
          <h2>Goals and Budget</h2>
          <div class="question-buttons">
            <label :class="{ error: hasErrors.mainGoal }">What's your main goal right now? (More sales, awareness, etc.)<span style="color:red;" v-if="hasErrors.mainGoal">*</span></label>
            <input type="text" v-model="goalsAndBudget.mainGoal" :class="{ 'input-error': hasErrors.mainGoal }" />

            <label :class="{ error: hasErrors.monthlyBudget }">How much can you spend on marketing each month?<span style="color:red;" v-if="hasErrors.monthlyBudget">*</span></label>
            <input type="text" v-model="goalsAndBudget.monthlyBudget" :class="{ 'input-error': hasErrors.monthlyBudget }" />

            <label :class="{ error: hasErrors.goodSalesMonth }">What would be a good month of sales for you?<span style="color:red;" v-if="hasErrors.goodSalesMonth">*</span></label>
            <input type="text" v-model="goalsAndBudget.goodSalesMonth" :class="{ 'input-error': hasErrors.goodSalesMonth }" />

            <label :class="{ error: hasErrors.marketingHelpNeeded }">What help do you need most with marketing?<span style="color:red;" v-if="hasErrors.marketingHelpNeeded">*</span></label>
            <input type="text" v-model="goalsAndBudget.marketingHelpNeeded" :class="{ 'input-error': hasErrors.marketingHelpNeeded }" />
          </div>
        </div>

        <!-- Email Address -->
        <div class="question-section">
        <div class="question-buttons">
            <label :class="{ error: hasErrors.email }">Please enter your email address: <span v-if="hasErrors.email">*</span></label>
            <input type="email" v-model="email" maxlength="200" placeholder="Enter your email address" />
        </div>
        </div>

        <!-- Submit Button -->
        <div class="submit-container">
          <button class="submit-button" @click="submitQuestionnaire">Submit</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StickyHeaderComponent from '../components/Header-Sticky.vue';

export default {
  components: {
    StickyHeaderComponent,
  },
  data() {
    return {
      submitted: false,
      companyInfo: {
        whatYouSell: '',
        typicalBuyer: '',
        reasonForStarting: '',
        salesMethod: '',
      },
      customerInfo: {
        likes: '',
        commonQuestions: '',
        priceRange: '',
      },
      marketingInfo: {
        communicationMethods: '',
        socialMediaPlatforms: '',
        effectiveStrategies: '',
        challenges: '',
      },
      goalsAndBudget: {
        mainGoal: '',
        monthlyBudget: '',
        goodSalesMonth: '',
        marketingHelpNeeded: '',
      },
      hasErrors: {
        whatYouSell: false,
        typicalBuyer: false,
        reasonForStarting: false,
        salesMethod: false,
        likes: false,
        commonQuestions: false,
        priceRange: false,
        communicationMethods: false,
        socialMediaPlatforms: false,
        effectiveStrategies: false,
        challenges: false,
        mainGoal: false,
        monthlyBudget: false,
        goodSalesMonth: false,
        marketingHelpNeeded: false,
        email: false
      },
    };
  },
  methods: {
    goHome() {
      this.$router.push('/'); // Redirect to home page
    },
    validateForm() {
      this.hasErrors = {
        whatYouSell: !this.companyInfo.whatYouSell,
        typicalBuyer: !this.companyInfo.typicalBuyer,
        reasonForStarting: !this.companyInfo.reasonForStarting,
        salesMethod: !this.companyInfo.salesMethod,
        likes: !this.customerInfo.likes,
        commonQuestions: !this.customerInfo.commonQuestions,
        priceRange: !this.customerInfo.priceRange,
        communicationMethods: !this.marketingInfo.communicationMethods,
        socialMediaPlatforms: !this.marketingInfo.socialMediaPlatforms,
        effectiveStrategies: !this.marketingInfo.effectiveStrategies,
        challenges: !this.marketingInfo.challenges,
        mainGoal: !this.goalsAndBudget.mainGoal,
        monthlyBudget: !this.goalsAndBudget.monthlyBudget,
        goodSalesMonth: !this.goalsAndBudget.goodSalesMonth,
        marketingHelpNeeded: !this.goalsAndBudget.marketingHelpNeeded,
        email: !this.email
      };

      // Return true if no errors are found
      return !Object.values(this.hasErrors).includes(true);
    },
    submitQuestionnaire() {
      if (this.validateForm()) {
        const formData = new URLSearchParams({
          'entry.110369946': this.companyInfo.whatYouSell,
          'entry.729857729': this.companyInfo.typicalBuyer,
          'entry.1630280148': this.companyInfo.reasonForStarting,
          'entry.809492341': this.companyInfo.salesMethod,
          'entry.831568193': this.customerInfo.likes,
          'entry.953936223': this.customerInfo.commonQuestions,
          'entry.1845557346': this.customerInfo.priceRange,
          'entry.367127236': this.marketingInfo.communicationMethods,
          'entry.1706575679': this.marketingInfo.socialMediaPlatforms,
          'entry.1030323208': this.marketingInfo.effectiveStrategies,
          'entry.776137407': this.marketingInfo.challenges,
          'entry.307711907': this.goalsAndBudget.mainGoal,
          'entry.35011008': this.goalsAndBudget.monthlyBudget,
          'entry.1182033988': this.goalsAndBudget.goodSalesMonth,
          'entry.403252712': this.goalsAndBudget.marketingHelpNeeded,
          'entry.729318164': this.email
        });

        // Open the URL in a new tab to submit the form
        const url = `https://docs.google.com/forms/d/e/1FAIpQLSc8qpKRZ9pbam0H16-B1sMEEBx36S3YYwbt7_CqaNDgkLIoBg/formResponse?${formData.toString()}`;
        window.open(url, '_blank');

        this.submitted = true;
      }
    },
  },
};
</script>

  
  <style scoped>
  /* Styles from Demo.vue */
  .demo-page {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    font-family: 'Montserrat', sans-serif;
  }
  .sticky-header {
    max-width: 1200px;
  }
  .title {
    font-size: 2em;
    margin-bottom: 40px;
    color: #381e72;
    text-align: center;
    margin-top: 15%;
  }
  
  .question-section {
    margin-bottom: 50px;
    margin-top: 5%;
  }
  
  .question-section h2 {
    font-size: 1.5em;
    margin-bottom: 20px;
    color: #381e72;
  }
  
  .question-buttons {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .question-buttons label {
    font-weight: bold;
  }
  
  .question-buttons input {
    padding: 10px;
    border: 1px solid #381e72;
    border-radius: 5px;
  }
  
  /* Error Styling */
  .error {
    color: red;
  }
  
  .input-error {
    border-color: red;
  }
  
  /* Submit Button */
  .submit-container {
    text-align: center;
    margin-top: 50px;
  }
  
  .submit-button {
    padding: 15px 30px;
    background-color: #381e72;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1.2em;
    transition: background-color 0.3s ease;
  }
  
  .submit-button:hover {
    background-color: #f4633a;
  }
  
  /* Thank You Message Styling */
  .thank-you-message {
    text-align: center;
    margin-top: 100px;
  }
  
  .thank-you-icon {
    font-size: 3em;
    color: #28a745;
    margin-bottom: 20px;
  }
  
  .thank-you-message h2 {
    font-size: 1.8em;
    color: #381e72;
    margin-bottom: 20px;
  }
  
  /* Home Button Styling */
  .home-button {
    background-color: #381e72;
    color: white;
    padding: 15px 30px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1.2em;
    transition: background-color 0.3s ease;
  }
  
  .home-button:hover {
    background-color: #f4633a;
  }
  </style>