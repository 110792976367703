<template>
  <div id="app">
    <router-view /> <!-- Dynamically loads the active page -->
  </div>
</template>

<script>
export default {};
</script>

<style>
body {
  margin: 0;
  font-family: Arial, sans-serif;
}

#app {
  font-family: 'Montserrat', sans-serif;
}
</style>