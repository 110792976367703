<template>
    <div>
      <StickyHeaderComponent />
      <section id="hero">
      <HeroSectionComponent />
    </section>
      <section id="how-it-works">
          <HowItWorks />
        </section>
        <section id="signup"> <!-- Add Sign-Up section here -->
          <SignupComponent />
        </section>
      <FooterComponent />
    </div>
  </template>
  
  <script>
  import StickyHeaderComponent from '../components/Header-Sticky.vue';
  import FooterComponent from '../components/Footer-Last.vue';
  import HeroSectionComponent from '../components/Hero-Main.vue';
  import HowItWorks from '../components/How-It-Works.vue';
  import SignupComponent from '../components/Sign-Up.vue';
  
  export default {
    components: {
      StickyHeaderComponent,
      HeroSectionComponent,
      HowItWorks,
      SignupComponent,
      FooterComponent,
    },
  };
  </script>
