<template>
  <section class="how-it-works">
    <h2>How It Works</h2>
    <div class="steps-container">
      <!-- Step cards with icons, titles, and descriptions -->
      <div class="step" v-for="(step, index) in steps" :key="index">
        <div class="step-header">
          <div class="icon-container">
            <i :class="step.icon"></i>
          </div>
          <h3>{{ step.title }}</h3>
        </div>
        <p class="step-description">{{ step.description }}</p>
      </div>
    </div>
    <!-- Demo Button -->
    <div class="demo-button-container">
      <router-link to="/demo">
        <button class="demo-button">DEMO</button>
      </router-link>
    </div>
    <div class="demo-button-container company-demo">
      <div>
        <h3>Don't have a marketing strategy?</h3>
      </div>
      <router-link to="/company-demo">
        <button class="demo-button">COMPANY STRATEGY DEMO</button>
      </router-link>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      steps: [
        { title: 'Time Constraints', description: 'Market research and testing typically cause 2+ weeks of delays', icon: 'fas fa-clock' },
        { title: 'Strategy Creation', description: 'Get AI-powered help to develop effective marketing strategies from scratch', icon: 'fas fa-lightbulb' },
        { title: 'AI Simulation', description: 'Test your ideas with simulated target audience reactions', icon: 'fas fa-bullseye' },
        { title: 'Rapid Results', description: 'Get instant insights to optimize your campaigns before launch', icon: 'fas fa-bolt' },
      ],
    };
  },
};
</script>

<style scoped>
/* General styling for How It Works section */
.how-it-works {
  padding: 50px;
  background-color: #f9f9f9;
  text-align: center;
}

.how-it-works h2 {
  font-size: 2.5em;
  margin-bottom: 30px;
  color: #381e72;
}

/* Steps container styled as rows */
.steps-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}

/* Step cards with icon, title, and description */
.step {
  display: flex;
  width: 75%;
  flex-direction: column;
  align-items: start;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  transition: transform 0.3s ease;
  text-align: start;
}

.step:hover {
  transform: translateY(-10px);
}

/* Header containing icon and title */
.step-header {
  display: flex;
  align-items: center;
  gap: 10px; /* Space between icon and title */
  margin-bottom: 10px; /* Space between header and description */
}

/* Icon styling */
.icon-container {
  font-size: 1.5em;
  color: #381e72;
}

/* Title styling */
.step-header h3 {
  font-size: 1.5em;
  color: #381e72;
  margin: 0; /* Remove default margin */
}

/* Description styling */
.step-description {
  font-size: 1.1em;
  color: #666;
  margin: 0;
}

.demo-button-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.demo-button-container.company-demo {
  flex-direction: column;
}

.demo-button {
  background-color: #f4633a;
  color: white;
  border: none;
  padding: 15px 30px;
  font-size: 1.2em;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.demo-button:hover {
  background-color: #e35227;
}
</style>